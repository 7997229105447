import { ReactNode, useEffect, useMemo } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { NextSeo } from 'next-seo';
import { LinkTag } from 'next-seo/lib/types';

import auth from '@udacity/ureact-hoth';

import { PageCategory } from '~/features/analytics/constants';
import { getGeoAnalyticsProperties } from '~/features/analytics/utils/get-analytics-properties';
import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';
import { publicEnv } from '~/features/environment/public';
import { removeExperimentRootFromUrl } from '~/features/experiments/utils';
import { ProgramContent } from '~/features/program/models/program-content';
import { ProgramMetadata } from '~/features/program/models/program-metadata';
import { ScholarshipContent } from '~/features/scholarships/models/scholarship-content';
import { SchoolContent } from '~/features/school/models/school-content';

import type { Page } from '../models/page';
import { transformSeoToNextSeo } from '../utils/transform-seo-to-next-seo';

const segmentWriteKey = publicEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
const environmentName = publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME;
const qualifiedKey = publicEnv.NEXT_PUBLIC_QUALIFIED_KEY;

const faviconTags: LinkTag[] = [
  {
    rel: 'icon',
    href: '/favicon/favicon.ico',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon/favicon-16x16.png',
  },
  {
    rel: 'apple-touch-icon',
    href: '/favicon/apple-touch-icon.png',
    sizes: '180x180',
  },
  {
    rel: 'manifest',
    href: '/favicon/site.webmanifest',
  },
];

export function PageHead({
  page,
  programContent,
  programMetadata,
  schoolContent,
  hotjarEvent,
  pageCategory,
  scholarshipContent,
  nextHeadChildren,
  loadChatbot = true,
  forcedCanonicalPath,
  additionalPageAnalytics,
}: {
  page: Page;
  programContent?: ProgramContent;
  programMetadata?: ProgramMetadata;
  schoolContent?: SchoolContent;
  hotjarEvent?: string;
  pageCategory?: PageCategory;
  scholarshipContent?: ScholarshipContent;
  nextHeadChildren?: ReactNode;
  loadChatbot?: boolean;
  forcedCanonicalPath?: string;
  additionalPageAnalytics?: Record<string, unknown>;
}) {
  const router = useRouter();
  const seo = scholarshipContent?.seo || schoolContent?.seo || page.seo;
  const seoProps = transformSeoToNextSeo(seo, programContent, programMetadata);
  const pageAnalytics = useMemo(() => {
    return {
      ...additionalPageAnalytics,
      name: page.eventName,
      site_identifier: 'udacity-web',
      user_status: auth.isAuthenticated() ? 'logged_in' : 'logged_out',
      user_has_active_subscription: getActiveSubscriptionCookie() ? true : false, // Cookie value can be undefined
      degree_key: programContent?.key,
      version: '2',
      category: pageCategory ?? PageCategory.Uncategorized,
      ...getGeoAnalyticsProperties(),
    };
  }, [page, programContent, pageCategory, additionalPageAnalytics]);

  if (!seoProps.canonical) {
    const url = removeExperimentRootFromUrl(new URL(`https://www.udacity.com${forcedCanonicalPath || router.asPath}`));
    // Note: we need to remove the hash and query params from the URL since it is not used for canonical url.
    url.hash = '';
    url.search = '';
    // Note: we need to add a canonical URL for pages if it isn't already defined in Sanity since we deploy our project to https://allaccess.web.udacity.com/ and these pages cannot be noindex/nofollow'd.
    seoProps.canonical = url.toString();
  }

  useEffect(() => {
    function routeChangeCompleteHandler() {
      if (typeof window !== 'undefined') {
        // Qualified
        if (typeof window.qualified !== 'undefined') {
          window.qualified('page');
        }
        // Segment Page Event
        if (typeof window.analytics !== 'undefined') {
          window.analytics.page(pageAnalytics?.category, pageAnalytics.name, pageAnalytics);
        }
      }
    }
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);
    return () => {
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
    };
  }, [router, pageAnalytics]);

  return (
    <>
      <NextSeo {...seoProps} additionalLinkTags={faviconTags} />
      <NextHead>
        <link crossOrigin='' href='https://sgmt.udacity.com' rel='preconnect' />
        {nextHeadChildren}
      </NextHead>

      {/* qualifiedKey is set to false in all envs except production and preview */}
      {qualifiedKey !== 'false' && loadChatbot && (
        <>
          <Script
            id='qualified-init'
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
                (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')
              `,
            }}
          />
          <Script async={true} id='qualified-js' src={`https://js.qualified.com/qualified.js?token=${qualifiedKey}`} />
        </>
      )}

      {/* segmentWriteKey is set to false in all envs except production and staging */}
      {segmentWriteKey !== 'false' && (
        <Script
          id='segment'
          strategy='afterInteractive'
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://sgmt.udacity.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";;analytics.SNIPPET_VERSION="4.15.3";
              analytics.load("${segmentWriteKey}");
              analytics.page("${pageAnalytics?.category}", "${pageAnalytics?.name}", ${JSON.stringify(pageAnalytics)});
              if (${
                publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' &&
                Boolean(publicEnv.NEXT_PUBLIC_DEBUG_ANALYTICS)
              }){
                console.log("page:", ${JSON.stringify(pageAnalytics)});
              }
              }}();
            `,
          }}
        />
      )}

      {hotjarEvent && environmentName === 'production' && (
        <Script
          id='hotjar'
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:350428,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                r.onload = function() {if(hj){hj('event', '${hotjarEvent}')}}
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}
    </>
  );
}
